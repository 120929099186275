import api from "./api"
import {getExcelNewAnswersStr, getQuestionNames, restoreAnswers} from "./questions";

export async function loadAnketConfigs(){
    return api.get('/ankets/getConfigsList')
}



export async function loadAnketFormData(anketQuerySpec){
    /*
    anketQuerySpec = {
        cznId: 39924,
        configId: 182928,
        startDate: '2024-01-01',
        endDate: '2025-04-12',
        page: 0,
        size: 100
    }
     */
    return api.get('/ankets/getAnkets', anketQuerySpec)
}

export async function loadRegionAnketFormData(configId, regionCode, startDate, endDate) {
    return api.get('/ankets/getRegionAnkets', {configId, regionCode, startDate, endDate})
}

export async function getAnketsCount(anketQuerySpec){
    // пример anketQuerySpec в функции loadAnketFormData
    return api.get('/ankets/getAnketsCount', anketQuerySpec)
}


// Обязательно для заполнения поле anketQuerySpec.configId
export async function exportAnketsToXlsx(anketsQuerySpec, sortField, getDepartmentById, getRegionNameById){
    let query = {
        headerCells: [
            {
                caption: "Ид",
                width: 5000,
            },
            {
                caption: "Ид конфигурации",
                width: 5000,
            },
            {
                caption: "ЦЗН",
                width: 10000,
            },
            {
                caption: "Регион",
                width: 10000,
            },
            {
                caption: "Регномер",
                width: 5000,
            },
            {
                caption: "Email",
                width: 7000,
            },
            {
                caption: "Дата заполнения",
                width: 7000,
            }
        ],
        rows: [],
    };

    const valuesReq = await loadAnketConfigs()
    if(!valuesReq.ok){
        return {ok: false, error: valuesReq.error}
    }
    const values = valuesReq.payload
    if (sortField != null) {
        values.sort((a, b) => (a[sortField] > b[sortField] ? 1 : -1));
    }

    const configsReq = await loadAnketConfigs()
    if(!configsReq.ok){
        return {ok: false, error: configsReq.error}
    }
    const configs = configsReq.payload
    const config = configs.find(e=>e.id === anketsQuerySpec.configId)
    config.anket = JSON.parse(config.anketJson)

    query.headerCells = query.headerCells.concat(getQuestionNames(config.anket).map((v) => {
        return {caption: v, width: 4000}
    }))

    for (let v of values) {
        let cznNameNumber = Number(v.cznName);
        let department = undefined;
        let region = undefined;
        if (!isNaN(cznNameNumber)) {
            department = await getDepartmentById(cznNameNumber);
            region = await getRegionNameById(cznNameNumber);
        }
        let row = [
            {cellDataType: "INTEGER", value: v.id},
            {cellDataType: "INTEGER", value: v.configId + " " + config.anket.title},
            {
                cellDataType: "STRING",
                value: department ? department.name : ""
            },
            {
                cellDataType: "STRING",
                value: region ? region.region?.name : ""
            },
            {cellDataType: "STRING", value: v.regnumber},
            {cellDataType: "STRING", value: v.email},
            {
                cellDataType: "STRING",
                value: formatDateTime(v.localDateTime),
            }
        ]
        let func = getExcelNewAnswersStr// v.id > 100 ? getExcelNewAnswersStr : getExcelAnswersStr
        row = row.concat(func(config.anket, v.answers).map((v) => {
            return {cellDataType: Number.isInteger(+v) ? "INTEGER" : "STRING", value: v}
        }))
        query.rows.push(row);
    }
    console.log(query)
    //await this.$downloadApi("ИС_Мониторинга_СЗН_Анкеты." + this.format.toLowerCase(), "/export/" + this.format, query, true);

}

function formatDateTime(date) {
    if (date == null) {
        return "";
    }
    let mdate = new Date(date);
    let options = {
        timeZone: "Europe/Moscow",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    };
    return mdate.toLocaleDateString("ru-RU", options);
}


const AnketService = {
    async loadAnketsByConfig (configId, err) {
        let req = await api.get("/ankets/getAnketsByConfigId", {configId: configId})
        if (req.ok) {
            return req.payload
        } else {
            err("Ошибка подключения к серверу или обработки запроса: loadAnketConfigs " + req.error)
        }
    },

    async loadAnketConfigs(err) {
        let req = await api.get("/ankets/getAllConfigs", {})
        if (req.ok) {
            for (let c of req.payload) {
                c.anket = JSON.parse(c.anketJson)
            }
            return req.payload
        } else {
            err("Ошибка подключения к серверу или обработки запроса: loadAnketConfigs " + req.error)
        }
    },


    getReadableAnswer(question){
        if(question.answer == null && question.answer === "")
            return "";
        if(question.multiple){
            let s = "";
            for(let a of question.answer){
                s += question.options[a].text + "; ";
            }
            return s;
        } else {
            return question.options[question.answer].text
        }
    },

    formAnswersReadableString(config, anketFormData) {
        // console.log(JSON.parse(config.anketJson))
        let answeredConfig = restoreAnswers(JSON.parse(config.anketJson), anketFormData.answers);
        let s = ""
        let stepNum = 0
        for(let step of answeredConfig.anket.steps){
            stepNum++
            s += "\nШаг " + stepNum
            for(let q of step.questions){
                s += "\n"+q.text + ": " + this.getReadableAnswer(q);
            }
        }
        return s
    },





}

export default AnketService
