<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Экспорт показателей</span>
             <a class="close" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#0033A0"/>
        </svg>
      </a>
    </v-card-title>

    <v-card-text>
      <div class="rating-popup">
      <v-container>        
        <v-row>
          <v-col cols="12">
            <h4>Формат</h4>
            <v-radio-group v-model="exportType" row>
              <v-radio label="XLSX" value="XLSX"></v-radio>
              <v-radio label="PDF" value="PDF"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h4>Фильтры</h4>
          </v-col>
          <v-col cols="12" sm="12">
            <v-autocomplete
                label="Мониторинг"
                :items="allMonitoringReportList"
                item-text="name"
                return-object
                v-model="monitoringReport"
                @input="monitoringChanged(); errorTextMonitoring = null"
            ></v-autocomplete>
            <span v-if="errorTextMonitoring" class="validate-error">{{ errorTextMonitoring }}</span>
          </v-col>

          <template v-if="monitoringReportType === 'region'">
            <v-col cols="6" sm="12">
              <div>Уровень выбранного мониторинга: "Регионы" <br> Укажите регион</div>
            </v-col>
            <v-col cols="12" sm="12">
              <v-select
                  label="Регионы"
                  :items="monitoringReport.regions"
                  item-text="name"
                  return-object
                  multiple
                  v-model="selectedRegions"
                  @change="errorTextRegion = null"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                  >
                    (+{{ selectedRegions.length - 1 }})
                  </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggleRegions()"
                  >
                    <v-list-item-action>
                      <v-icon class="icon-item"
                              :color="selectedRegions.length > 0 ? 'indigo darken-4' : ''"
                              :class="regionsIcon"
                      >
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Выбрать все
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
              <span v-if="errorTextRegion" class="validate-error">{{ errorTextRegion }}</span>
            </v-col>
          </template>

          <template v-else-if="monitoringReportType === 'czn'">
            <v-col cols="6" sm="12">
              <div>Уровень выбранного мониторинга: "ЦЗН" <br> Укажите сначала регион, а затем выберите ЦЗН</div>
            </v-col>
            <v-col cols="12" sm="12">
              <v-select
                  label="Регионы"
                  :items="departmentsRegionsList"
                  item-text="name"
                  return-object
                  multiple
                  v-model="selectedDepartmentsRegions"
                  @input="updateMonitoringDepartmentsList(); errorTextCZNRegion = null"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                  >
                    (+{{ selectedDepartmentsRegions.length - 1 }})
                  </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggleDepartmentsRegions()"
                  >
                    <v-list-item-action>
                      <v-icon class="icon-item"
                              :class="departmentsRegionsIcon"
                              :color="selectedDepartmentsRegions.length > 0 ? 'indigo darken-4' : ''">
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Выбрать все
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
              <span v-if="errorTextCZNRegion" class="validate-error">{{ errorTextCZNRegion }}</span>
            </v-col>
            <v-col cols="12" sm="12">
              <v-select
                  label="ЦЗН"
                  :items="departmentsList"
                  item-text="name"
                  return-object
                  multiple
                  v-model="selectedDepartments"
                  @change="errorTextCZNDepartment = null"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                  >
                    (+{{ selectedDepartments.length - 1 }})
                  </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggleDepartments()"
                  >
                    <v-list-item-action>
                      <v-icon class="icon-item"
                              :class="departmentsIcon"
                              :color="selectedDepartments.length > 0 ? 'indigo darken-4' : ''">
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Выбрать все
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
              <span v-if="errorTextCZNDepartment" class="validate-error">{{ errorTextCZNDepartment }}</span>
            </v-col>
          </template>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h4>Сортировка</h4>
          </v-col>
          <v-col cols="12" sm="12">
            <v-select
                dense
                label="По имени"
                :items="sortList"
                item-text="name"
                item-value="value"
                v-model="ascendingByName"
                @change="errorTextAscending = null"
            ></v-select>
            <span v-if="errorTextAscending" class="validate-error">{{ errorTextAscending }}</span>
          </v-col>
        </v-row>
      </v-container>
     </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          class="btn btn-outline"
          text
          @click="$emit('close')"
      >
        Закрыть
      </v-btn>
      <v-btn
          class="btn btn-primary"
          @click="submit()"
      >
        Скачать
      </v-btn>
      <div
          v-if="errorText != null"
          class="error"
          style="position: fixed; bottom: 0"
      >
        Ошибка: {{ errorText }}
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from "@/modules/api";


export default {
  name: 'StandardExportDialog',

  data: () => ({
    exportType: "XLSX",
    allMonitoringReportList: [],
    regionsList: [],
    selectedRegions: [],
    departmentsRegionsList: [],
    selectedDepartmentsRegions: [],
    departmentsList: [],
    selectedDepartments: [],
    monitoringReport: null,
    monitoringReportType: '',
    ascendingByName: null,
    errorTextMonitoring: null,
    errorTextAscending: null,
    errorTextRegion: null,
    errorTextCZNRegion: null,
    errorTextCZNDepartment: null,
    errorText: null,
    sortList: [
      {name: "В алфавитном порядке", value: true},
      {name: "В обратном алфавитном порядке", value: false}
    ],
  }),

  computed: {
    selectedAllRegions () {
      return this.selectedRegions.length === this.monitoringReport.regions.length
    },
    selectedSomeRegions () {
      return this.selectedRegions.length > 0 && !this.selectedAllRegions
    },
    regionsIcon () {
      if (this.selectedAllRegions) return 'icon-close-box'
      if (this.selectedSomeRegions) return 'icon-minus-box'
      return 'icon-checkbox-blank-outline'
    },

    selectedAllDepartmentsRegions () {
      return this.selectedDepartmentsRegions.length === this.departmentsRegionsList.length
    },
    selectedSomeDepartmentsRegions () {
      return this.selectedDepartmentsRegions.length > 0 && !this.selectedAllDepartmentsRegions
    },
    departmentsRegionsIcon () {
      if (this.selectedAllDepartmentsRegions) return 'icon-close-box'
      if (this.selectedSomeDepartmentsRegions) return 'icon-minus-box'
      return 'icon-checkbox-blank-outline'
    },

    selectedAllDepartments () {
      return this.selectedDepartments.length === this.departmentsList.length
    },
    selectedSomeDepartments () {
      return this.selectedDepartments.length > 0 && !this.selectedAllDepartments
    },
    departmentsIcon () {
      if (this.selectedAllDepartments) return 'icon-close-box'
      if (this.selectedSomeDepartments) return 'icon-minus-box'
      return 'icon-checkbox-blank-outline'
    },
  },

  methods: {
    async getMonitoringReportList() {
      let req = await api.get("/monitoring/typical/getList");
      if (req.ok) {
        this.allMonitoringReportList = req.payload;
        // Сортировка по id
        this.allMonitoringReportList.sort((a, b) => (a.id < b.id ? 1 : -1));
      } else {
        console.log('get error');
      }
    },

    monitoringChanged() {
      this.checkMonitoringReportType();
      if (this.monitoringReportType === "czn") {
        this.sortMonitoringDepartmentByRegion()
      }
      this.selectedRegions = [];
      this.selectedDepartmentsRegions = [];
      this.selectedDepartments = [];
      this.ascendingByName = null;
    },

    checkMonitoringReportType() {
      if ("czns" in this.monitoringReport) {
        this.monitoringReportType = "czn";
      } else if ("regions" in this.monitoringReport) {
        this.monitoringReportType = "region";
      }
    },

    sortMonitoringDepartmentByRegion() {
      let map = new Map(),
          departmentsByRegion;
      for (let dep of this.monitoringReport.czns) {
        if (!map.has(dep.region.code)) {
          map.set(dep.region.code, {
            name: dep.region.name,
            code: dep.region.code,
            departments: [dep]})
        } else {
          map.get(dep.region.code).departments.push(dep)
        }
      }
      departmentsByRegion = Array.from(map.values());
      departmentsByRegion.sort((a, b) => (a.regionName > b.regionName ? 1 : -1));
      for (let dep of departmentsByRegion) {
        dep.departments.sort((a, b) => (a.name > b.name ? 1 : -1))
      }
      this.departmentsRegionsList = departmentsByRegion;
    },

    updateMonitoringDepartmentsList() {
      let czns = [];
      for (let region of this.selectedDepartmentsRegions) {
        czns = czns.concat(region.departments);
      }
      this.departmentsList = czns;
      this.selectedDepartments = [];
    },

    // toggleSelect(selectedAllItems, selectedItems, itemsList) {
    //   console.log('selectedAllItems', selectedAllItems);
    //   console.log('selectedItems', selectedItems);
    //   console.log('itemsList', itemsList);
    //   this.$nextTick(() => {
    //     if (selectedAllItems) {
    //       selectedItems = [];
    //     } else {
    //       selectedItems = itemsList.slice();
    //     }
    //   })
    //   this.$forceUpdate()
    // },

    toggleRegions() {
      this.$nextTick(() => {
        if (this.selectedAllRegions) {
          this.selectedRegions = [];
        } else {
          this.selectedRegions = this.monitoringReport.regions.slice();
        }
      })
    },

    toggleDepartmentsRegions() {
      this.$nextTick(() => {
        if (this.selectedAllDepartmentsRegions) {
          this.selectedDepartmentsRegions = [];
        } else {
          this.selectedDepartmentsRegions = this.departmentsRegionsList.slice();
        }
      })
    },

    toggleDepartments() {
      this.$nextTick(() => {
        if (this.selectedAllDepartments) {
          this.selectedDepartments = [];
        } else {
          this.selectedDepartments = this.departmentsList.slice();
        }
      })
    },

    submit() {
      if (this.validateSubmit()) {
        if (this.exportType === "XLSX") {
          this.getExportFile("/monitoring/typical/config/getExcelFile", ".xlsx")
        } else if (this.exportType === "PDF") {
          this.getExportFile("/monitoring/typical/config/getPDFFile", ".pdf")
        }
        this.$emit('close')
      }
    },

    validateSubmit() {
      if (this.monitoringReport == null) {
        this.errorTextMonitoring = "Укажите мониторинг";
        return false
      }

      if (this.monitoringReportType === "region") {
        if (this.selectedRegions.length === 0) {
          this.errorTextRegion = "Укажите регион";
          return false
        }
      } else if (this.monitoringReportType === "czn") {
        if (this.selectedDepartmentsRegions.length === 0) {
          this.errorTextCZNRegion = "Укажите регион";
          return false
        }
        if (this.selectedDepartments.length === 0) {
          this.errorTextCZNDepartment = "Укажите ЦЗН";
          return false
        }
      }

      if (this.ascendingByName == null) {
        this.errorTextAscending = "Укажите сортировку";
        return false
      }

      return true
    },

    async getExportFile(path, fileExtension){
      let obj = {
        monitoringId: Number(this.monitoringReport.id),
        ascendingByName: this.ascendingByName
      };
      if (this.monitoringReportType === "czn") {
        obj.czns = this.getArrOfNames(this.selectedDepartments)
      } else if (this.monitoringReportType === "region") {
        obj.regions = this.getArrOfNames(this.selectedRegions)
      }
      // console.log('ExcelFileObj', obj)
      let req = await api.postDownloadFile(path, obj, {monitoringId: this.monitoringReport.id});
      if (req.ok) {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(req.payload);
        link.download = `Экспорт - ${this.monitoringReport.name}.${fileExtension}`
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
        console.log('download error');
      }
    },

    getArrOfNames(arr) {
      let arrOfNames = [];
      for (let a of arr) {
        arrOfNames.push(a.name)
      }
      return arrOfNames
    },
  },

  async beforeMount() {
    await this.getMonitoringReportList()
  },
}
</script>

<style lang="scss" scoped>
.validate-error {
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  font-size: 12px;
  color: red;
}
</style>
